<template>
  <div>
    <!--头部-->
    <template v-if="$route.name == 'courseWorkbook'">
      <div id="membership" v-if="this.$store.state.userInfo.is_workbook == '2'">
        <div class="heardermembership">
          <div class="tu">
            <img src="../../assets/img/1.4.0.6/icon_zybtb@2x.png" alt="" />
          </div>
          <!-- <div class="tu2" v-if="list.status == 2">
          <img :src="list.src" alt="" />
        </div>

        <div class="tu3" v-if="list.status == 3">
          <img :src="list.src" alt="" />
        </div> -->
          <div class="right">
            <div class="right_font1">作业</div>
            <div class="right_font2">
              为教育机构提供统一收发作业，批量批改点评的督学功能；机构可为学员自主安排学习计划，多种形式教学，点评互动，增加学员的活跃性；支持在线评分，激励学员，刺激学员PK，低成本传播。
            </div>
            <div class="right_button">
              <el-button
                type="primary"
                size="medium"
                @click="kaitong"
                style="width: 112px"
              >
                立即开通
              </el-button>
              <div class="price">
                ￥999
                <span
                  class="red"
                  style="font-size: 14px; marign-left: 5px; font-weight: bold"
                >
                  /年
                </span>
              </div>
              <div class="strick">￥1599</div>
            </div>
          </div>
        </div>
        <noOpen :list="list"></noOpen>

        <openingfunction
          v-if="status3"
          :dialogstatus.sync="status3"
        ></openingfunction>
      </div>
      <div id="courseWorkbook" v-else>
        <div class="header">
          <div class="boxTest">
            <div class="c1" @click="editActivity" v-if="showRemoveNew">
              <p>新建作业本</p>
            </div>
          </div>

          <div class="storeSelect_2">
            <el-select v-model="post.type" placeholder="全部类型">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>

          <div class="input">
            <el-input
              placeholder="输入作业本名称搜索"
              v-model="post.search"
              class="input-with-select"
            >
              <!-- 搜索 -->
            </el-input>
          </div>
        </div>

        <div class="contentVideo">
          <div class="rightMenu">
            <!-- 右侧内容 列表- 分页 -->
            <div class="rightCon">
              <pagination2
                :option="post"
                url="/Homework/workbookList"
                ref="child"
              >
                <template v-slot:default="{ tableData }">
                  <!-- @sort-change="sortChange" -->
                  <el-table
                    :data="tableData"
                    max-height:200
                    :header-cell-style="{
                      background: 'rgba(245,245,245,1)',
                      color: '#333333',
                    }"
                  >
                    <el-table-column label="作业本">
                      <template slot-scope="scope">
                        <p class="zuoye" :title="scope.row.name">
                          {{ scope.row.name }}
                        </p>
                      </template>
                    </el-table-column>

                    <el-table-column label="作业">
                      <template slot-scope="scope">
                        <p>{{ scope.row.theme_num }}</p>
                      </template>
                    </el-table-column>

                    <el-table-column label="类型">
                      <template slot-scope="scope">
                        <p>
                          {{ scope.row.type | getPicetype }}
                        </p>
                      </template>
                    </el-table-column>

                    <el-table-column label="关联内容" prop="sc_name">
                      <template slot-scope="scope">
                        <div class="ellipsis">
                          {{ scope.row.sc_name || '-' }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="创建时间">
                      <template slot-scope="scope">
                        {{
                          scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                        }}
                      </template>
                    </el-table-column>

                    <el-table-column label="操作" min-width="110">
                      <template slot-scope="scope">
                        <div class="mouseRight">
                          <div class="btns flex-center">
                            <span
                              type="text"
                              class="text"
                              style="color: #0aa29b"
                              @click="goEditor(scope.row.homework_id)"
                            >
                              管理
                            </span>
                            <span class="span_1" v-if="showRemoveEdit">|</span>
                            <span
                              v-if="showRemoveEdit"
                              class="text"
                              @click="editActivity(scope.row.homework_id)"
                            >
                              编辑
                            </span>

                            <span class="span_1" v-if="showRemoveDel">|</span>
                            <span
                              v-if="showRemoveDel"
                              class="text"
                              @click="delActivity(scope.row.homework_id)"
                            >
                              删除
                            </span>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </pagination2>
              <!--分页-->
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 海报分享 -->
    <sharePop
      v-if="status2"
      :val="find"
      :dialogstatus.sync="status2"
    ></sharePop>
    <router-view></router-view>
  </div>
</template>
<script>
import bg from '@/mixin/background'
// import API from '@/request/api'
import noOpen from '@/components/copyrightprotect/noOpen' //功能介绍
import openingfunction from '@/components/copyrightprotect/openingfunction'
import sharePop from '@/views/courseWorkbook/components/sharePop'
export default {
  name: 'courseWorkbook',
  components: {
    sharePop,
    openingfunction,
    noOpen,
  },

  mixins: [bg],
  computed: {
    is_electronics() {
      return this.$store.state.userInfo.is_electronics
    },
    //新建
    showRemoveNew() {
      return this.$store.state.roots.includes(129)
    },
    //编辑
    showRemoveEdit() {
      return this.$store.state.roots.includes(130)
    },
    //删除
    showRemoveDel() {
      return this.$store.state.roots.includes(131)
    },
    //新建主题
    showRemoveNewtheme() {
      return this.$store.state.roots.includes(132)
    },
    //编辑主题
    showRemoveEdittheme() {
      return this.$store.state.roots.includes(133)
    },
    //删除主题
    showRemoveDeltheme() {
      return this.$store.state.roots.includes(134)
    },
    //点评主题
    showRemoveCommenttheme() {
      return this.$store.state.roots.includes(135)
    },
    //删除学生作业
    showRemoveDelstuWork() {
      return this.$store.state.roots.includes(136)
    },
  },
  data() {
    return {
      list: {
        function_tu: require('@/assets/img/1.4.0.6/img_zybjs@2x.png'),
      },

      find: {}, //弹窗所需的内容

      status2: false, //分享弹窗

      shareInfo: {},

      status3: false, //客服弹窗

      shareId: '',

      options2: [
        {
          value: 0,
          label: '全部类型',
        },
        {
          value: 1,
          label: '关联课程',
        },
        {
          value: 2,
          label: '关联章节',
        },
        {
          value: 3,
          label: '关联公开课',
        },
      ],

      tableData: [],

      post: {
        type: 0,
        search: '',
      },
    }
  },
  filters: {
    getPicetype(val) {
      let text2
      switch (Number(val)) {
        case 1:
          text2 = '关联课程'
          break
        case 2:
          text2 = '关联章节'
          break
        case 3:
          text2 = '关联公开课'
          break
        default:
          text2 = '-'
          break
      }
      return text2
    },
  },

  methods: {
    //点击开通按钮
    kaitong() {
      this.status3 = true
    },

    // 显示分享弹窗页面
    toggleShareId() {
      this.shareId = ''
    },

    // 获取分享资料数据
    getShareInfo(val) {
      this.find = val
      this.$nextTick(() => {
        this.status2 = true
      })
    },

    //点击查看
    goEditor(id) {
      // console.log(id)
      this.$router.push({
        path: '/courseWorkbook/editor/' + id,
      })
    },

    editActivity(id) {
      //点击新建资料编辑
      //检测id数据类型 新建跳转id传new
      if (typeof id == 'string') {
        this.$router.push({
          path: '/courseWorkbook/newData/' + id,
        })
      } else {
        this.$router.push({
          path: '/courseWorkbook/newData/' + 'new',
        })
      }
    },

    delActivity(id) {
      //删除
      this.$confirm(
        '删除后会从网校移除，不可恢复，已购和未购的学员都无法查看！',
        '删除作业',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        this.$http({
          url: '/Homework/isDel',
          data: {
            homework_id: id,
          },
          callback: () => {
            this.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            this.$refs.child.reset()
          },
          error: () => {
            this.$root.prompt('删除失败')
          },
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#membership {
  margin: 20px;
}

.zuoye {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

::v-deep #noOpen {
  margin: 0;
}

::v-deep .introduction {
  margin-top: 0;
}

.heardermembership {
  background-color: #fff;
  padding: 20px;
  display: flex;
  margin-bottom: 20px;
  // border-bottom: 20px solid rgb(245, 245, 245);

  .tu {
    width: 131px;
    height: 131px;
    background: #e8f3ff;
    border-radius: 20px;

    img {
      margin: 28px auto 0;
      height: 82px;
      width: auto;
    }
  }

  // .tu2 {
  //   background: #ff6c32;

  //   img {
  //     margin-top: 26px;
  //   }
  // }

  // .tu3 {
  //   background-color: #30b031;

  //   img {
  //     margin-top: 26px;
  //   }
  // }

  .right {
    width: calc(100% - 161px);
    margin-left: 30px;

    .right_font1 {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
    }

    .right_font2 {
      height: 40px;
      margin-top: 14px;
      margin-bottom: 21px;
      font-size: 13px;
      color: #808080;
      line-height: 20px;
    }

    .right_button {
      display: flex;
      align-items: flex-end;

      .price {
        font-size: 18px;
        font-weight: bold;
        color: #ff3535;
        margin-left: 24px;
        margin-right: 12px;
      }

      .strick {
        font-size: 13px;
        text-decoration: line-through;
        color: #808080;
        line-height: 13px;
      }
    }
  }
}

::v-deep .el-tabs__nav-wrap:after {
  height: 1px;
}

::v-deep .el-tabs__nav-scroll {
  padding: 0 20px;
}

.priceBox {
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

p {
  color: #333;
}

.disabled {
  color: #c0c4cc;
  cursor: not-allowed !important;
  opacity: 0.6;
}

::v-deep.el-dropdown-menu {
  .el-button--text {
    color: none;
  }

  width: 136px;

  p {
    width: 115px;
  }
}

// .disabled {
//   pointer-events: none;
//   cursor: default;
//   opacity: 0.6;
// }

.el-dialog__body {
  .el-select {
    width: 100%;
  }
}

.contentVideo {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  .rightMenu {
    flex: 1;
    height: 100%;
    overflow-x: hidden;
    padding: 0px 20px 0px 20px;

    .titleActivitName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      line-height: 14px;

      .left {
        width: 68px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          object-fit: contain;
          height: 38px;
          width: 100%;
        }
      }

      .right {
        margin-left: 10px;
        width: calc(100% - 78px);
        min-width: 166px;

        .right > p {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 14px;
        }

        .imgBox {
          display: flex;
          width: 55%;

          .zi1 {
            font-size: 12px;
            color: #ff3535;
            line-height: 12px;
            margin-top: 12px;
          }

          .zi2 {
            text-decoration: line-through;
            margin-left: 8px;
            font-size: 12px;
            color: #999999;
            line-height: 12px;
            margin-top: 12px;
          }

          img {
            width: 8px;
            height: 7px;
            margin-top: 4px;
            margin-right: 3px;
          }

          p {
            height: 12px;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: #666666;
            line-height: 12px;
          }

          div {
            display: flex;
          }
        }

        .imgBox div:nth-child(2) {
          margin-left: 10px;
        }
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

#courseWorkbook {
  background: #fff;
  margin: 20px 20px;
  border-top: 1px solid #fff;
}

.header {
  display: flex;
  margin-bottom: 20px;
  min-width: 900px;
  margin-top: 20px;
  padding: 0 20px 0 20px;

  .uploading {
    width: 100px;
    height: 36px;
    background-color: #0aa29b;
    border-radius: 4px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 14px;
    // margin-top: 20px;
    margin-left: 20px;
    margin-right: 30px;
    cursor: pointer;
  }

  .boxTest {
    flex: 1;

    // margin-top: 20px;
    .c1 {
      flex: 1;
      width: 100px;
      height: 40px;
      background: #0aa29b;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;

      p {
        height: 14px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 40px;
      }
    }
  }

  .storeSelect {
    width: 160px;
    height: 36px;
    border-radius: 4px;
    margin-right: 20px;
  }

  .storeSelect_2 {
    width: 160px;
    height: 36px;
    border-radius: 4px;
  }

  .input {
    margin-left: 20px;
  }
}

.extensionHoverBox {
  p {
    width: 420px;
    height: 54px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #4a4a4a;
    line-height: 18px;
  }

  img {
    width: 297px;
    height: 416px;
    margin-left: 49px;
    margin-top: 10px;
  }
}

.mouseRight {
  width: 120px;

  span {
    color: #0aa29b;
  }

  .text {
    cursor: pointer;
  }

  .span_1 {
    margin: 0 5px;
  }
}

// .btns {
//   @extend %btns;
//   position: relative;
// }

// ::v-deep .el-button--text{
//     color: #606266;
// }
</style>
